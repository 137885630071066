import React from "react";
// import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import ReactPlayer from "react-player";
import "./video.css";
const Video = () => {
  return (
    <div className="container-fluid mt-5 mb-5 pt-5 pb-5" id="services">
      <Container className="text-center">
        <p className="video-div-header ">
          Discover the latest <snap>&#35;</snap>Technology
        </p>
        <p className="video-div-description mb-5">
          The virtual reality with everything for everyone
        </p>
      </Container>
      <div className="d-flex justify-content-center">
        <ReactPlayer
          className="react-player"
          url="https://youtu.be/gCs3ysr7UKA"
        />
      </div>
      <div className="container video-btn mt-5 mb-5 px-5 py-2 d-flex justify-content-center align-items-center">
        <a href="https://cosedge.com/" style={{textDecoration:"none", color:"white"}}>Explore IT Services</a>
      </div>
    </div>
  );
};

export default Video;
