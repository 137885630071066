import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import React, { useState } from "react";
import { db } from "../../config/config";

const Input = () => {

  const [ email, setemail ] = useState("");

  const onSubscribe = async () => {
    const regex =  /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
    const userEmail = email.toLowerCase();

    if(!userEmail.match(regex)){
      document.getElementById("email").style.borderBottom = "solid 3px red";
      alert("Enter a valid email");
    }else{
      // collection ref
      const newsLettersCollectionRef = collection(db, "newsLetters");

      // query the email
      const q = query(newsLettersCollectionRef, where("email", "==", userEmail));
      const querySnapshot = await getDocs(q);
      
      // if user email not exist
      if(querySnapshot.size === 0){
        // add data
        addDoc(newsLettersCollectionRef, { email : email }).then((res) => {
          console.log(res);
          document.getElementById("email").style.borderBottom = "solid 1px white";
          setemail("");
          alert("Thanky you. NewsLetters Subscribed.");          
        }).catch((e) => {
          console.log(e);
        })
      }else{
        document.getElementById("email").style.borderBottom = "solid 3px red";
        alert("Email already used.");
      }
    }
  }

  return (
    <div className="col-md-4 col-sm-2 col-12 text-center">
      <p className="footer-input-header">JOIN OUR NEWSLETTER</p>
      <div className="input-group mb-3">
        <input
          id="email"
          type="text"
          value={email}
          className="form-control footer-input"
          placeholder="Your mail..."
          aria-label="Your mail..."
          aria-describedby="basic-addon2"
          onChange={(e) => setemail(e.target.value)}
          style = {{color : 'white'}}
        />
        <span onClick={() => onSubscribe()} className="input-group-text" id="basic-addon2" style={{cursor : 'pointer'}}>
          SUBSCRIBE
        </span>
      </div>
    </div>
  );
};

export default Input;
