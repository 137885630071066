import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAluV9EEd-RLjm5Pxthy2oP8FrQgistUvA",
  authDomain: "cosedge-ccbe9.firebaseapp.com",
  databaseURL: "https://cosedge-ccbe9-default-rtdb.firebaseio.com",
  projectId: "cosedge-ccbe9",
  storageBucket: "cosedge-ccbe9.appspot.com",
  messagingSenderId: "94229525275",
  appId: "1:94229525275:web:a9d94e7f66bc6fa9e619be",
  measurementId: "G-4RM9W1Y5JG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Firestore
const db = getFirestore(app);

export {
    app, db
};